import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// eslint-disable-next-line no-unused-vars
import { getDatabase, ref, push } from 'firebase/database';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import Projects from './Projects';
import StripeDonation from './StripeDonation';

// Component for Newsletter section
const NewsletterSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [quizAnswers, setQuizAnswers] = useState({ q1: '', q2: '', q3: '' });
  const [message, setMessage] = useState('');
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleQuizChange = (e) => {
    const { name, value } = e.target;
    setQuizAnswers(prevAnswers => ({
      ...prevAnswers,
      [name]: value
    }));
  };

  useEffect(() => {
    if (quizAnswers.q1 === 'correct1' && quizAnswers.q2 === 'correct2' && quizAnswers.q3 === 'correct3') {
      setIsQuizCompleted(true);
    } else {
      setIsQuizCompleted(false);
    }
  }, [quizAnswers]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Client-side validation
    if (!name.trim() || name.length > 50) {
      setMessage('Please enter a valid name (max 50 characters).');
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(email)) {
      setMessage('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Prepare data for server-side validation
      const newsletterData = {
        name: name.trim(),
        email: email.trim().toLowerCase(),
        timestamp: Date.now(),
        quizAnswers
      };

      // Send data to server (Firebase function) for processing
      const response = await fetch('https://us-central1-react-website-51c67.cloudfunctions.net/processNewsletterSignup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newsletterData),
      });

      const result = await response.json();

      if (result.success) {
        setMessage('Thanks for joining my newsletter, you are awesome!');
        setName('');
        setEmail('');
        setQuizAnswers({ q1: '', q2: '', q3: '' });
      } else {
        setMessage(result.error || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.content}>
      <h2 style={styles.sectionTitle}>Join my AI Engineer Newsletter</h2>
      <p style={{...styles.sectionTitle, fontSize: '16px', marginTop: '-15px', marginBottom: '20px', color: '#999'}}>
        Stay ahead of the curve with bi-monthly updates on AI engineering breakthroughs, expert analysis, and exclusive developer resources. Get curated insights and occasional special offers, delivered straight to your inbox – no fluff, just value.
      </p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <label htmlFor="name" style={styles.label}>Name:</label>
        <input 
          type="text" 
          id="name" 
          name="name" 
          required 
          maxLength="50"
          style={styles.input} 
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        
        <label htmlFor="email" style={styles.label}>Email:</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          required 
          style={styles.input} 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div style={styles.quiz}>
          <h3 style={styles.quizTitle}>Answer the following questions correctly to submit:</h3>
          <label style={styles.quizLabel}>1. What is RAG short for?</label>
          <select name="q1" value={quizAnswers.q1} onChange={handleQuizChange} style={styles.select}>
            <option value="">Select an answer</option>
            <option value="wrong1">Random Arbitrary GPU</option>
            <option value="correct1">Retrieval-Augmented Generation</option>
            <option value="wrong2">Random AI Generation</option>
          </select>

          <label style={styles.quizLabel}>2. What is the most popular AI programming language?</label>
          <select name="q2" value={quizAnswers.q2} onChange={handleQuizChange} style={styles.select}>
            <option value="">Select an answer</option>
            <option value="wrong1">Rust</option>
            <option value="wrong2">Go</option>
            <option value="correct2">Python</option>
          </select>

          <label style={styles.quizLabel}>3. Can an LLM learn from context?</label>
          <select name="q3" value={quizAnswers.q3} onChange={handleQuizChange} style={styles.select}>
            <option value="">Select an answer</option>
            <option value="wrong1">No</option>
            <option value="correct3">Yes</option>
          </select>
        </div>

        <input 
          type="submit" 
          value={isSubmitting ? "Submitting..." : "Submit"} 
          style={styles.submitButton} 
          disabled={!isQuizCompleted || isSubmitting} 
        />
      </form>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

// Component for displaying videos
const VideoContainer = ({ title, description, embedCode }) => (
  <div style={styles.videoContainer}>
    <div style={styles.videoWrapper}>
      <div dangerouslySetInnerHTML={{ __html: embedCode }} className="video-iframe"/>
    </div>
    <h3 style={styles.videoTitle}>{title}</h3>
    <p style={styles.videoDescription}>{description}</p>
  </div>
);

// Videos Section Component
const VideosSection = () => {
  const videos = [  {
    title: "5 CHALLENGES for Claude Computer Use: Here's What Happened",
    description: "In this exciting video, we put the Claude computer to the test with five unique challenges that push its limits! Watch as we explore its problem-solving skills, creativity, and adaptability in real-time. From generating stories to solving complex puzzles, see how Claude",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/aN-IbSyIw7Q?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "AI Agents 101 Masterclass | Tool Use - Function Calling - Claude 3.5 New - OpenAI ++",
    description: "Dive into the world of AI with our \"AI Agents 101 Masterclass\"! In this comprehensive tutorial, we explore the latest advancements in AI, including the powerful functionalities of Claude 3.5 and the innovative tool use of OpenAI's latest features",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/ODUN-XTEzvQ?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "Claude Computer Use TESTED - This is VERY Promising!",
    description: "In this exciting video, we dive deep into the capabilities of the Claude computer, putting it through a series of challenging tests to see just how well it performs! Join us as we explore its innovative features, user-friendly interface, and the potential it holds",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/A5RfSftJRw8?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "OpenAI Swarm AI Agents - Is It Time To Be ALL IN on Agentic Workflows?",
    description: "In this thought-provoking video, we dive into the revolutionary concept of OpenAI Swarm AI Agents and explore the potential of agentic workflows in transforming how we work and collaborate. Discover how these intelligent agents can enhance productivity, creativity, and decision-making in",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/2JRqZfwdbAs?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "AI Agent Contacts the REAL WORLD for the FIRST TIME - OpenAI Realtime API",
    description: "In this groundbreaking video, witness the moment when an AI agent makes contact with the real world for the very first time using the OpenAI Realtime API. Watch as we explore the capabilities and implications of this cutting-edge technology, showcasing live interactions and the",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/ekqtnJ71zeI?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "OpenAI Realtime API - The NEW ERA of Speech to Speech? - TESTED",
    description: "In this exciting video, we dive deep into the groundbreaking OpenAI Realtime API, exploring its potential to revolutionize speech-to-speech interactions. Watch as we put this cutting-edge technology to the test, showcasing its impressive capabilities in real-time translation,",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/qq13yG32rUk?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "OpenAI DevDay | Realtime Speech to Speech API + Image Fine-tuning TESTED",
    description: "Join us for an exciting deep dive into OpenAI's DevDay as we explore the groundbreaking Realtime Speech to Speech API and Image Fine-tuning capabilities! In this video, we'll provide hands-on demonstrations, testing the latest features, and showcasing their real",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/K5pbPZse0oI?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "Was I Wrong About AI Agents? | INSANE OpenAI-o1 Planning Capabilities",
    description: "In this eye-opening video, we dive deep into the revolutionary planning capabilities of OpenAI's latest AI agents. Join us as we explore my initial misconceptions about AI's potential and how these advanced systems are reshaping our understanding of intelligent automation. Watch as",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/9UreCL6oFsc?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "AI App of the Week 3 - OpenAI-o1 Investment App (Chain Prompting)",
    description: "In this week's episode of \"AI App of the Week,\" we dive into the innovative OpenAI-o1 Investment App! Discover how this cutting-edge tool utilizes chain prompting to revolutionize your investment strategies. We'll walk you through its features, share tips on",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/rUP-sX1DcPM?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "MIND BLOWING AI Voice (NotebookLM) & My AI Favorite Workflows",
    description: "In this video, we dive into the incredible capabilities of NotebookLM's mind-blowing AI voice technology! Discover how this cutting-edge tool can transform your creative processes and enhance productivity. I'll share my favorite workflows that leverage this powerful AI, showcasing real-life",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/_xvtOgxwwHI?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "OpenAI-o1 x Cursor | Use Cases - XML Prompting - AI Coding ++",
    description: "In this exciting video, we dive into the powerful collaboration between OpenAI's advanced models and Cursor, showcasing innovative use cases for XML prompting and AI coding. Discover how to enhance your coding efficiency, streamline workflows, and unlock the full potential of AI in",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/K0it90lnqnk?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "OpenAI-o1 on Cursor | First Impressions and Tests vs Claude 3.5",
    description: "In this exciting video, we dive into the latest AI powerhouse, OpenAI-o1, as we put it to the test against Claude 3.5! Join us for our first impressions, a side-by-side comparison, and a series of practical",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/Nl6SVPpRUQI?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  
  {
    title: "AI App of the Week 1 - Cursor AI / Claude 3.5 - Full Tutorial",
    description: "Dive into our first episode of \"AI App of the Week\" as we explore the powerful capabilities of Cursor AI and the latest enhancements of Claude 3.5! In this full tutorial, you'll learn how to leverage these cutting-edge tools to supercharge",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/7pFqM3lejLQ?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "AI App of the Week 2 - AI Agents with Supabase Memory | Cursor & V0",
    description: "Join us in this week's episode of \"AI App of the Week\" as we dive into the cutting-edge features of AI agents powered by Supabase Memory! Discover how Cursor and V0 are revolutionizing the way we interact with AI, enhancing productivity and",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/BIBDq-65m9I?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "Replit AI Agent - My First Impression | Cursor Challenger?",
    description: "In this video, I dive into my first experience with the Replit AI Agent, putting its coding capabilities to the test in a thrilling Cursor Challenger challenge! Join me as I explore its features, assess its performance, and compare it to other coding tools",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/tD9ASvTYmyw?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "Coding with AI: The Beginning of the Personalized Software Era?",
    description: "Join us in this exciting video as we explore how AI is revolutionizing the world of coding! Discover the cutting-edge tools and techniques that are paving the way for personalized software solutions tailored to individual needs. We'll dive into real-life examples, expert insights",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/-sgGH8lD_XU?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  },
  {
    title: "Coding With Cursor AI: Lets Build a Full-Stack Web App feat Stripe | Full Tutorial",
    description: "Join us in this comprehensive tutorial as we dive into building a full-stack web application using Cursor AI! In this video, we'll walk you through each step of the process, integrating Stripe for seamless payment processing. Whether you're a beginner or looking to enhance",
    embedCode: "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/jnaeB6LjoQs?si=nzrnnVyhmY2YK5JD\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" allowfullscreen></iframe>"
  }

    // New videos will be inserted here by the add_video.js script
  ];

  return (
    <div style={styles.content}>
      <h2 style={styles.sectionTitle}>Latest Videos</h2>
      <div style={styles.videoGrid}>
        {videos.map((video, index) => (
          <VideoContainer key={index} title={video.title} description={video.description} embedCode={video.embedCode} />
        ))}
      </div>  
    </div>
  );
};

// Component for Payment Success page
const PaymentSuccess = () => {
  return (
    <div style={styles.messageContainer}>
      <h2 style={styles.successMessage}>Payment Successful!</h2>
      <p style={styles.redirectMessage}>Thank you for your support!</p>
    </div>
  );
};

// Component for Payment Cancel page
const PaymentCancel = () => {
  return (
    <div style={styles.messageContainer}>
      <h2 style={styles.cancelMessage}>Payment Cancelled</h2>
      <p style={styles.redirectMessage}>You have cancelled the payment.</p>
    </div>
  );
};

// Main Website Component
const Website = () => {
  const [firebaseApp, setFirebaseApp] = useState(null);
  const [activeSection, setActiveSection] = useState('main');

  useEffect(() => {
    fetch('https://us-central1-react-website-51c67.cloudfunctions.net/getConfig')
      .then(response => response.json())
      .then(data => {
        // Initialize Firebase first
        const app = initializeApp(data.firebaseConfig);
        setFirebaseApp(app);

        // Initialize App Check before Analytics
        initializeAppCheck(app, {
          provider: new ReCaptchaV3Provider('6Lf7Gw8qAAAAAFL1kNYlaRs1Zc-3_r5KO6txvjoL'),
          isTokenAutoRefreshEnabled: true
        });

        // Wrap analytics in try-catch
        try {
          const analytics = getAnalytics(app);
          logEvent(analytics, 'page_view', {
            page_title: 'Home Page',
            page_location: window.location.href,
          });
        } catch (error) {
          console.warn('Analytics initialization failed:', error);
          // Continue execution even if analytics fails
        }
      })
      .catch(error => {
        console.error('Error fetching Firebase config:', error);
        // Continue execution even if Firebase config fails
      });
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      setActiveSection(hash || 'main');
    };

    window.addEventListener('hashchange', handleHashChange);

    handleHashChange(); // Initial check

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const handleNavClick = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'projects':
        return <Projects />;
      case 'videos':
        return <VideosSection />;
      case 'newsletter':
        return firebaseApp && <NewsletterSection />;
      case 'buy-me-coffee':
        return (
          <section>
            <h2 style={styles.sectionTitle}>Buy Me a Coffee:</h2>
            <StripeDonation />
          </section>
        );
      case 'success':
        return <PaymentSuccess />;
      case 'cancel':
        return <PaymentCancel />;
      default:
        return (
          <>
            <section>
              <h2 style={styles.sectionTitle}>About Me</h2>
              <ul style={styles.list}>
                <li>Kristian Fagerlie</li>
                <li>Norway</li>
                <li>Youtuber / AI Engineer</li>
              </ul>
            </section>
            
            <section>
              <h2 style={styles.sectionTitle}>Projects</h2>
              <p>A random selection of stuff I made:</p>
              <ol style={styles.list}>
                <li><a href="https://onetinytool.com" style={styles.link}>OneTinyTool.com</a>, My 100% AI Developed Tool Website</li>
                <li><a href="https://github.com/AllAboutAI-YT/easy-local-rag" style={styles.link}>Easy Local RAG</a>, Ollama 100% local AI RAG system (600+ stars)</li>
                <li><a href="https://github.com/AllAboutAI-YT/cursor_prompts" style={styles.link}>Cursor Prompts</a>, my prompt to speed up coding on Cursor</li>
                <li><a href="https://aiswe.tech" style={styles.link}>This LLM Powered Website</a>, AI powered React website developed with Claude 3.5</li>
              </ol>
            </section>
            
            <section>
              <h2 style={styles.sectionTitle}>Contact / Socials</h2>
              <ul style={styles.list}>
                <li>Email (<a href="mailto:kris@allabtai.com" style={styles.link}>kris@allabtai.com</a>)</li>
                <li>YouTube (<a href="https://www.youtube.com/c/AllAboutAI" style={styles.link}>All About AI</a>)</li>
                <li>GitHub (<a href="https://github.com/AllAboutAI-YT" style={styles.link}>Open GH</a>)</li>
                <li>LinkedIn (<a href="https://www.linkedin.com/in/kristian-fagerlie/" style={styles.link}>KF</a>)</li>
                <li>X (<a href="https://x.com/AllAbtAI" style={styles.link}>AAA</a>)</li>
              </ul>
            </section>
          </>
        );
    }
  };

  return (
    <div style={styles.body}>
      <header style={styles.header}>
        <h1 style={styles.title}>All About AI</h1>
        <nav style={styles.nav}>
          <a href="#main" onClick={() => handleNavClick('main')} style={styles.navItem}>Main</a>
          <a href="https://microservice-app-7b4f0.web.app/" style={styles.navItem}>Services</a>
          <a href="#videos" onClick={() => handleNavClick('videos')} style={styles.navItem}>Videos</a>
          <a href="#newsletter" onClick={() => handleNavClick('newsletter')} style={styles.navItem}>Newsletter</a>
          <a href="#buy-me-coffee" onClick={() => handleNavClick('buy-me-coffee')} style={styles.navItem}>Buy Me a Coffee</a>
        </nav>
      </header>
      
      <main style={styles.content}>
        {renderContent()}
      </main>
      
      <footer style={styles.footer}>
        <span>© AllAboutAI 2024, inspired by the original | <a href="https://sdomi.pl/" style={styles.link}>Sdomi</a></span>
      </footer>

      {/* ElevenLabs Widget - Temporarily disabled */}
      {/* <div dangerouslySetInnerHTML={{ __html: '<elevenlabs-convai agent-id="x8qDUtBN6mHpfJ8CQXV5"></elevenlabs-convai>' }} /> */}
    </div>
  );
};

// CSS Styles
const styles = {
  body: {
    backgroundColor: '#0f0f0f',
    color: '#ddd',
    fontFamily: '"IBM VGA 8", monospace',
    fontSize: '16px',
    lineHeight: 1.4,
    margin: 0,
    padding: '15px',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  title: {
    fontSize: '40px',
    color: '#fff',
    textShadow: '5px 5px #555',
    margin: '30px 0 20px',
  },
  nav: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  navItem: {
    color: '#1c1',
    textDecoration: 'none',
    textShadow: '2px 2px #444',
  },
  content: {
    maxWidth: '900px',
    margin: '0 auto',
    flex: 1,
    padding: '20px',
  },
  sectionTitle: {
    fontSize: '24px',
    color: '#fff',
    marginBottom: '10px',
  },
  list: {
    listStyleType: 'square',
    paddingLeft: '20px',
  },
  highlight: {
    color: '#1c1',
  },
  link: {
    color: '#1c1',
    textDecoration: 'none',
  },
  footer: {
    textAlign: 'center',
    marginTop: '40px',
    fontSize: '14px',
  },
  form: {
    backgroundColor: '#1a1a1a',
    border: '1px solid #1c1',
    padding: '20px',
    marginTop: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#1c1',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginBottom: '15px',
    backgroundColor: '#0f0f0f',
    border: '1px solid #1c1',
    color: '#ddd',
    fontFamily: 'monospace',
  },
  submitButton: {
    backgroundColor: '#1c1',
    color: '#0f0f0f',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    fontFamily: 'monospace',
    fontWeight: 'bold',
  },
  videoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
  },
  videoContainer: {
    backgroundColor: '#1a1a1a',
    border: '1px solid #1c1',
    padding: '15px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 aspect ratio
    height: 0,
    overflow: 'hidden',
    marginBottom: '10px',
  },
  videoTitle: {
    color: '#1c1',
    marginBottom: '10px',
    fontSize: '18px',
  },
  videoDescription: {
    fontSize: '14px',
    flexGrow: 1,
  },
  message: {
    marginTop: '10px',
    color: '#1c1',
  },
  countdown: {
    textAlign: 'center',
    color: '#fff',
    marginBottom: '20px',
    fontFamily: '"IBM VGA 8", monospace',
    fontSize: '20px',
  },
  horizontalLine: {
    border: '1px solid #1c1',
    margin: '20px 0',
  },
  quiz: {
    marginBottom: '20px',
  },
  quizTitle: {
    fontSize: '20px',
    color: '#fff',
    marginBottom: '10px',
  },
  quizLabel: {
    color: '#1c1',
    display: 'block',
    marginBottom: '5px',
  },
  select: {
    width: '100%',
    padding: '8px',
    marginBottom: '15px',
    backgroundColor: '#0f0f0f',
    border: '1px solid #1c1',
    color: '#ddd',
    fontFamily: 'monospace',
  },
  messageContainer: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#1a1a1a',
    border: '1px solid #1c1',
    marginTop: '20px',
  },
  successMessage: {
    color: '#1c1',
    fontSize: '24px',
  },
  cancelMessage: {
    color: '#ff0000',
    fontSize: '24px',
  },
  redirectMessage: {
    color: '#fff',
    marginTop: '10px',
  },
};

export default Website;